import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { OOI_EXPERIMENTS } from '@app/experiments';
import { useSelector } from '../../../common/components/runtime-context';
import Tags from '../../../common/components/tags';
import { getTagCloudWidgetData } from '../../store/tag-cloud.selectors';

/** @deprecated Remove when merging {@link OOI_EXPERIMENTS.USE_NEW_TAG_CLOUD_SETTINGS_URL} */
const TagCloud: React.FC = () => {
  const tagCloudWidget = useSelector(getTagCloudWidgetData);

  return (
    <Tags
      tags={tagCloudWidget.tags}
      translations={tagCloudWidget.translations}
    />
  );
};

export default TagCloud;
